import { createI18n } from 'vue-i18n';
import { locales } from '~/config/i18n';

export const createLocalI18n = (messages: Record<string, any>) => {
  const { $i18n } = useNuxtApp();

  return createI18n({
    legacy: false,
    locale: $i18n.locale.value,
    locales,
    messages,
  }).global;
};
